import { Tag } from 'antd4';
import React from 'react';

import './Fields.scss';

const CustomField = ({ width, label, data, customRender }) => {
  return (
    <div
      key={label}
      className={`input-container  d-flex align-items-center w-${width ??
        '25'}    `}
    >
      <div
        className={`w-full status-container px-2 py-2.5 bg-white border-mediumgray justify-between d-flex align-items-center `}
      >
        {label ? (
          <div className="">
            <Tag className="input-prefix border-radius ">{label}</Tag>
          </div>
        ) : (
          <>&nbsp;</>
        )}
        <div className="flex h-full  ">{customRender(data)}</div>
      </div>
    </div>
  );
};

export default CustomField;
